<template>
  <base-material-card
    color="warning"
    icon="mdi-calendar-alert"
    title="Important Dates"
    class="no-shadow"
  >
    <v-expansion-panels
      v-if="policies.length"
      flat
      focusable
    >
      <v-expansion-panel
        v-for="policy in policies"
        :key="policy.id"
      >
        <v-expansion-panel-header class="text-subtitle-2">
          {{ policy.policy_number }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <policy-form :policy="policy" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <p
      v-else
      class="text-center"
    >
      This Client Has no Policies
    </p>
  </base-material-card>
</template>

<script>
  export default {
    components: {
      PolicyForm: () => import('../../components/policy/PolicyForm.vue'),
    },

    props: {
      policies: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
